import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { analytics } from "../../../components/src/Firebase";
import { logEvent } from "firebase/analytics";
import { generateRequestMessage, isTokenExpired, logoutAndRedirectToLoginPage } from '../../ss-cms-common-components/src/Utilities/Utilities';
import { getStorageData } from "../../../framework/src/Utilities";
interface ICard {
  tagId:string;
  image:string;
  description:string;
  title:string;
  script:string;
}

interface IToastMessage {
  message: string | JSX.Element;
  type: "success" | "error";
}

interface IaddScript {
  data: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: string;
        script: string;
        google_tag_manager_id: number;
        created_at: string;
        updated_at: string;
      };
    };
  }
}

interface IaddSriptError {
  error: {
    script: string[];
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  hideLoader: () => void;
  showLoader: () => void;
  showToast : (obj : IToastMessage) => void;
 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: boolean;
  editMode:null | ICard;
  txtInputValue:string;
  googleTagScript: ICard;
  erroText:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  googleTagManagerId:string;
  deleteScriptId:string;
  updateScriptId:string;
  addScriptId:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceMessage)

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen:false,
      editMode:null,
      txtInputValue:"",
      googleTagScript:{description:"",image:"",script:"",tagId:"",title:''},
      erroText:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.googleTagManagerId = '';
    this.deleteScriptId = '';
    this.updateScriptId = '';
    this.addScriptId = '';

    logEvent(analytics, "Analytics::Web::Load");
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      if(isTokenExpired(message)){
        return logoutAndRedirectToLoginPage(this.props);
      }  
      this.handleResponse(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

    // Customizable Area Start
  async doButtonPressed() {
    logEvent(analytics, "Analytics::Web::button_clicked");
  }

  getRosponseObjectFromMessage = (message:Message) => {
    const apiResponseData = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
  }

  handleResponse = (message: Message) => {
    const { apiSuccessMessageData, apiErrorMessageData, apiResponseData } = this.getRosponseObjectFromMessage(message);
    this.props.hideLoader();
    try {
      if (!!apiErrorMessageData) {
        this.props.showToast({ message: apiErrorMessageData, type: "error" });
        return;
      }

      if (apiSuccessMessageData.data) {
        (apiResponseData === this.googleTagManagerId) && (this.handleGoogleTagManagerData(message));
        ([this.addScriptId, this.updateScriptId].includes(apiResponseData)) && this.handleAddScriptMessage(message);
        return;
      }

      if (apiSuccessMessageData.message) {
        ([this.deleteScriptId, this.updateScriptId].includes(apiResponseData)) && this.handleDeleteMessage(message);
        return;
      }

      if(apiSuccessMessageData.error) {
        ([ this.addScriptId, this.updateScriptId ].includes(apiResponseData)) && this.handleAddScriptErrorMessage(message);
        return;
      }

      this.props.showToast({message:configJSON.wrongDataCameFromBackend,type: 'error'});
    }
    catch (error) {
      this.props.showToast({ message: configJSON.networkErrorMsg, type: "error" });
    }
  } 

  handleHowToUse = () => {
    const anchor = document.createElement("a");
    anchor.href = configJSON.howUseLink;
    anchor.target =  "_blank";
    anchor.click();
    this.toggleModal();
  }

  handleGoogleTagManagerData = (message: Message) => {
    const { apiSuccessMessageData } = this.getRosponseObjectFromMessage(message);

    const image = apiSuccessMessageData.data.data.attributes.image.image ?? "";
    const description = apiSuccessMessageData.data.data.attributes.description ?? "";
    const title = apiSuccessMessageData.data.data.attributes.name ?? "";
    let script = "";
    let tagId = "";
    if(apiSuccessMessageData.data.data.attributes.script){
      script = apiSuccessMessageData.data.data.attributes.script.script || "";
      tagId = apiSuccessMessageData.data.data.attributes.script.id || "";
    }
   


    this.setState({ googleTagScript: { title, description, image, tagId, script }, txtInputValue: script });
  }

  handleDeleteMessage = (message: Message) => {
    const { apiSuccessMessageData } = this.getRosponseObjectFromMessage(message);  
        const isScriptDeleted = apiSuccessMessageData.message === configJSON.deleteSuccessfullyMsg;
    if (isScriptDeleted) {
      this.setState(previous => (
        { txtInputValue: "", googleTagScript: { ...previous.googleTagScript, tagId: "", script: "" } }
      ));
    } else {
      this.setState(previous => ({ txtInputValue: previous.googleTagScript.script }));
    }
        this.props.showToast({
          message: isScriptDeleted ? configJSON.scriptDeletedSuccessfullyMsg : apiSuccessMessageData.message,
          type: isScriptDeleted ? "success" : "error"
        })
  }

  handleAddScriptMessage = (message: Message) => {
    const { apiSuccessMessageData } = this.getRosponseObjectFromMessage(message);
    const newData = apiSuccessMessageData as IaddScript;
    const script = newData.data?.data?.attributes?.script ?? "";
    const tagId = newData.data?.data?.attributes?.id ?? "";
    this.setState(previous => ({googleTagScript:{...previous.googleTagScript, script , tagId}}));
    this.toggleModal();
    this.props.showToast({
      message: configJSON.scriptUpdatedSuccessfullyMsg,
      type: "success"
    });
  }

  handleAddScriptErrorMessage = (message: Message) => {
    const { apiSuccessMessageData } = this.getRosponseObjectFromMessage(message);
    const errorData = apiSuccessMessageData as IaddSriptError;
    let errorInfo: string = "";
    Object.entries(errorData.error).forEach(([errorKey, errorValue])=>{
      errorInfo += errorKey + " "
      errorValue.forEach(error => {
        errorInfo += error + " ";
      })
      errorInfo+="\n";
    })
    this.setState({erroText:errorInfo});
    this.props.showToast({ message: errorInfo, type: "error" });
  }

  handleDelete = async () => {
    const { tagId } = this.state.googleTagScript;
    const endPoint = `${configJSON.updateDeleteScriptApi}${tagId}`;
    const deleteMessage = await generateRequestMessage(endPoint,configJSON.deleteMethod);
    this.deleteScriptId = deleteMessage.messageId;
    this.props.showLoader();
    runEngine.sendMessage(deleteMessage.id,deleteMessage);
    this.toggleModal();
  }

  toggleModal = () => {
    this.setState(prev => ({ isModalOpen: !prev.isModalOpen, txtInputValue:"",erroText:"" }));
  }

  

  handleUpdateScript = async () => {
    const { txtInputValue, googleTagScript } = this.state;
    const { tagId, script } = googleTagScript;
    if(script === txtInputValue) {
      this.props.hideLoader();
      this.toggleModal();
      return;
    };
    const urlencoded = new FormData;
    urlencoded.append("script", txtInputValue);
    const endPoint = `${configJSON.updateDeleteScriptApi}${tagId}`;
    const token = await getStorageData(configJSON.adminToken);
    const headers = {
      token
    }
    const updateMessage = await generateRequestMessage(
      endPoint, configJSON.putMethod,
    );
    updateMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers))
    updateMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), urlencoded);
    this.updateScriptId = updateMessage.messageId;
    runEngine.sendMessage(updateMessage.id, updateMessage);
  }

  handleAdd = async () => {
    const { googleTagScript, txtInputValue: script } = this.state;
    const { tagId } = googleTagScript;
    this.props.showLoader();

    if (tagId) {
      this.handleUpdateScript();
      return;
    }

    const addScriptMessage = await generateRequestMessage(
      configJSON.addScriptApi, configJSON.postMethod,
    );
    this.addScriptId = addScriptMessage.messageId;
    addScriptMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ script }));
    runEngine.sendMessage(addScriptMessage.id, addScriptMessage);
  }

  handleConnect = () => {
    this.setState(previous => ({ isModalOpen: true, txtInputValue:previous.googleTagScript.script }));
  }
  
  handleTextChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({txtInputValue: event.target.value})
  }

  getScriptDataFromServer = async () => {
    const scriptMessage = await generateRequestMessage(
      configJSON.googleTagManagerApi,configJSON.getMethod
      );
    this.googleTagManagerId = scriptMessage.messageId;
    runEngine.sendMessage(scriptMessage.id, scriptMessage);
    this.props.showLoader();
  }
  async componentDidMount() {
    this.getScriptDataFromServer()
  }

  // Customizable Area End
}
